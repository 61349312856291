import React, { createContext, useContext, useEffect, useState, ReactNode, useCallback } from 'react';
import { BattlegroundCardInterface } from '../interfaces/battleground-card.interface';
import { useSnackbar } from './common/SnackbarContext';

interface CardSelectionState {
  selectedCardIds: Set<number>;
  currentSelectedIds: Set<number>;
  loadSelectedCardIdsFromQueryParam: () => void;
  addCardsToSelection: (cards: BattlegroundCardInterface[]) => void;
  toggleCardSelection: (id: number, name?: string) => void;
  resetSelectedCardIds: () => void;
}

interface SharedUrlState {
  sharedUrlCardIds: Set<string>;
  setSharedUrlCardIds: React.Dispatch<React.SetStateAction<Set<string>>>;
}

interface ViewState {
  isSelectedCardsView: boolean;
  setIsSelectedCardsView: (val: boolean) => void;
  hideSpells: boolean;
  toggleHideSpells: () => void;
  loadHideSpellsFromQueryParam: () => void;
}

interface PlayerModeState {
  selectedSoloDuo: string;
  setSelectedSoloDuo: (val: string) => void;
  loadPlayerModeFromQueryParam: () => void;
}

interface CardTypeState {
  selectedCardTypes: Set<number>;
  toggleCardTypeSelection: (cardTypeId: number) => void;
  loadSelectedCardTypesFromQueryParam: () => void;
}

interface TierState {
  selectedTiers: Set<number>;
  toggleTierSelection: (tier: number) => void;
  loadTiersFromQueryParam: () => void; // todo move
}

interface MinionTypeState {
  selectedMinionTypes: Set<number>;
  toggleMinionTypeSelection: (typeId: number) => void;
  loadMinionTypesFromQueryParam: () => void; // todo move
}

interface KeywordState {
  selectedKeywordIds: Set<number>;
  toggleKeywordSelection: (keywordId: number) => void;
  loadKeywordsFromQueryParam: () => void; // todo move
}

interface CardStateContextProps {
  cardSelection: CardSelectionState;
  sharedUrl: SharedUrlState;
  view: ViewState;
  playerMode: PlayerModeState;
  keyword: KeywordState;
  cardType: CardTypeState;
  tier: TierState;
  minionType: MinionTypeState;
}

const CardStateContext = createContext<CardStateContextProps | undefined>(undefined);

export const CardStateProvider: React.FC<{
  children: ReactNode,
  getQueryParam: (param: string) => string
  getIdsFromQueryParam: (param: string) => number[]
  modifyListInQueryParams: (key: string, id: string, shouldAdd: boolean) => void;
  updateQueryParams: (params: { [key: string]: string | undefined }) => void;
}> = ({
        children,
        getQueryParam,
        getIdsFromQueryParam,
        modifyListInQueryParams,
        updateQueryParams
      }) => {

  const [isSelectedCardsView, setIsSelectedCardsView] = useState<boolean>(false);
  const [selectedCardIds, setSelectedCardIds] = useState<Set<number>>(() => {
    const storedCardIds = sessionStorage.getItem('selectedCardIds');
    return storedCardIds ? new Set(JSON.parse(storedCardIds)) : new Set<number>();
  });

  const [sharedUrlCardIds, setSharedUrlCardIds] = useState<Set<string>>(() => {
    const storedUrlCardIds = sessionStorage.getItem('sharedUrlCardIds');
    return storedUrlCardIds ? new Set<string>(JSON.parse(storedUrlCardIds)) : new Set<string>();
  });

  const [currentSelectedIds, setCurrentSelectedIds] = useState(new Set<number>());

  const [selectedCardTypes, setSelectedCardTypes] = useState(new Set<number>());

  const [selectedSoloDuo, setSelectedSoloDuo] = useState('');

  const [hideSpells, setHideSpells] = useState(false);

  const [selectedKeywordIds, setSelectedKeywordIds] = useState(new Set<number>());

  const [selectedTiers, setSelectedTiers] = useState(new Set<number>());

  const [selectedMinionTypes, setSelectedMinionTypes] = useState(new Set<number>());

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    sessionStorage.setItem('selectedCardIds', JSON.stringify(Array.from(selectedCardIds)));
  }, [selectedCardIds]);

  useEffect(() => {
    sessionStorage.setItem('sharedUrlCardIds', JSON.stringify(Array.from(sharedUrlCardIds)));
  }, [sharedUrlCardIds]);

  // Selected Cards
  const loadSelectedCardIdsFromQueryParam = useCallback(() => {
    const uniqueIds = new Set<number>();
    const cardIds = getIdsFromQueryParam('cardIds');
    if (cardIds && cardIds.length > 0) {
      for (const id of cardIds) {
        uniqueIds.add(id);
      }
    }
    setCurrentSelectedIds(uniqueIds);
  }, [getIdsFromQueryParam, setCurrentSelectedIds]);

  const addCardsToSelection = (cards: BattlegroundCardInterface[]) => {
    setSelectedCardIds(prevSelected => {
      const newSelected = new Set(prevSelected);
      let snackbarMessage = '';
      let snackbarType: 'success' | 'error';

      let added = 0;
      cards.forEach(card => {
        if (card.id && !newSelected.has(card.id)) {
          newSelected.add(card.id);
          added++;
        }
      });

      if (added > 0) {
        snackbarMessage = `Added ${added} to Selected 📌 `;
        snackbarType = 'success';
      } else {
        snackbarMessage = 'No new cards were added to Selected 📌';
        snackbarType = 'error';
      }

      showSnackbar(snackbarMessage, snackbarType);

      return newSelected;
    });
  };

  const toggleCardSelection = (id: number, name?: string) => {
    setSelectedCardIds(prevSelected => {
      const newSelected = new Set(prevSelected);
      let snackbarMessage = '';
      let snackbarType: 'success' | 'error';
      if (newSelected.has(id)) {
        newSelected.delete(id);
        snackbarMessage = `Deselected ${name ? `<strong>${name}</strong>` : 'Card'}`;
        snackbarType = 'error';
      } else {
        newSelected.add(id);
        snackbarMessage = `Selected ${name ? `<strong>${name}</strong>` : 'Card'}`;
        snackbarType = 'success';
      }

      showSnackbar(snackbarMessage, snackbarType);

      return newSelected;
    });
  };

  const resetSelectedCardIds = () => {
    setSelectedCardIds(new Set<number>());
  };

  const cardSelection: CardSelectionState = {
    selectedCardIds,
    currentSelectedIds,
    loadSelectedCardIdsFromQueryParam,
    addCardsToSelection,
    toggleCardSelection,
    resetSelectedCardIds,
  };

  // Keyword Ids
  const loadKeywordsFromQueryParam = useCallback(() => {
    const uniqueIds = new Set<number>();
    const keywords = getIdsFromQueryParam('keywords');
    if (keywords && keywords.length > 0) {
      for (const id of keywords) {
        uniqueIds.add(id);
      }
    }
    setSelectedKeywordIds(uniqueIds);
  }, [getIdsFromQueryParam, setSelectedKeywordIds]);

  const toggleKeywordSelection = (keywordId: number) => {
    if (keywordId) {
      let shouldAdd = true;
      if (selectedKeywordIds.has(keywordId)) {
        shouldAdd = false;
      }
      modifyListInQueryParams('keywords', keywordId.toString(), shouldAdd);
    }
  };

  const keyword: KeywordState = {
    selectedKeywordIds,
    toggleKeywordSelection,
    loadKeywordsFromQueryParam
  };

  // Card Type State
  const loadSelectedCardTypesFromQueryParam = useCallback(() => {
    const uniqueIds = new Set<number>();
    const cardTypes = getIdsFromQueryParam('cardTypes');
    if (cardTypes && cardTypes.length > 0) {
      for (const id of cardTypes) {
        uniqueIds.add(id);
      }
    }
    setSelectedCardTypes(uniqueIds);
  }, [getIdsFromQueryParam, setSelectedCardTypes]);

  const toggleCardTypeSelection = (cardType: number) => {
    if (cardType) {
      let shouldAdd = true;
      if (selectedCardTypes.has(cardType)) {
        shouldAdd = false;
      }
      modifyListInQueryParams('cardTypes', cardType.toString(), shouldAdd);
    }
  };

  const cardType: CardTypeState = {
    selectedCardTypes,
    toggleCardTypeSelection,
    loadSelectedCardTypesFromQueryParam
  };

  // Tier State
  const loadTiersFromQueryParam = useCallback(() => {
    const uniqueIds = new Set<number>();
    const tiers = getIdsFromQueryParam('tiers');
    if (tiers && tiers.length > 0) {
      for (const id of tiers) {
        uniqueIds.add(id);
      }
    }
    setSelectedTiers(uniqueIds);
  }, [getIdsFromQueryParam, setSelectedTiers]);

  const toggleTierSelection = (tier: number) => { // todo move
    if (tier) {
      let shouldAdd = true;
      if (selectedTiers.has(tier)) {
        shouldAdd = false;
      }
      modifyListInQueryParams('tiers', tier.toString(), shouldAdd);
    }
  };

  const tier: TierState = {
    selectedTiers,
    toggleTierSelection,
    loadTiersFromQueryParam
  };

  // Minion Type State
  const loadMinionTypesFromQueryParam = useCallback(() => {
    const uniqueIds = new Set<number>();
    const minionTypes = getIdsFromQueryParam('minionTypes');
    if (minionTypes && minionTypes.length > 0) {
      for (const id of minionTypes) {
        uniqueIds.add(id);
      }
    }
    setSelectedMinionTypes(uniqueIds);
  }, [getIdsFromQueryParam, setSelectedMinionTypes]);

  const toggleMinionTypeSelection = (typeId: number) => {
    if (typeId) {
      let shouldAdd = true;
      if (selectedMinionTypes.has(typeId)) {
        shouldAdd = false;
      }
      modifyListInQueryParams('minionTypes', typeId.toString(), shouldAdd);
    }
  };

  const minionType: MinionTypeState = {
    selectedMinionTypes,
    toggleMinionTypeSelection,
    loadMinionTypesFromQueryParam
  }

  // Shared URL State
  const sharedUrl: SharedUrlState = {
    sharedUrlCardIds,
    setSharedUrlCardIds,
  };

  // View State
  const loadHideSpellsFromQueryParam = useCallback(() => {
    const foundParam = getQueryParam('hideSpells');
    if (foundParam && foundParam === 'true') {
      setHideSpells(true);
    } else if (hideSpells) {
      updateQueryParams({ hideSpells: 'true' });
    }
  }, [getQueryParam, hideSpells, setHideSpells, updateQueryParams]);

  const toggleHideSpells = () => {
    if (hideSpells) {
      updateQueryParams({ hideSpells: undefined });
      setHideSpells(false);
    } else {
      updateQueryParams({ hideSpells: 'true' });
    }
  };

  const view: ViewState = {
    isSelectedCardsView,
    setIsSelectedCardsView,
    hideSpells,
    toggleHideSpells,
    loadHideSpellsFromQueryParam
  };

  // Player Mode
  const loadPlayerModeFromQueryParam = useCallback(() => {
    const playerMode = getQueryParam('playerMode');
    if (playerMode) {
      setSelectedSoloDuo(playerMode)
    } else if (selectedSoloDuo) {
      updateQueryParams({ playerMode: selectedSoloDuo });
    }
  }, [getQueryParam, selectedSoloDuo, setSelectedSoloDuo, updateQueryParams]);

  const playerMode: PlayerModeState = {
    loadPlayerModeFromQueryParam,
    selectedSoloDuo,
    setSelectedSoloDuo,
  };

  return (
    <CardStateContext.Provider
      value={{
        cardSelection,
        sharedUrl,
        view,
        playerMode,
        keyword,
        cardType,
        tier,
        minionType
      }}>
      {children}
    </CardStateContext.Provider>
  );
};

export const useCardState = () => {
  const context = useContext(CardStateContext);
  if (!context) {
    throw new Error('useCardState must be used within a CardStateProvider');
  }
  return context;
};
