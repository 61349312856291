import React, { createContext, useContext, useEffect, useState } from 'react';

interface GlobalStateContextProps {
  isUserToolsMenuVisible: boolean;
  setIsUserToolsMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  darkMode: boolean;
  toggleDarkMode: () => void;
}

const GlobalStateContext = createContext<GlobalStateContextProps | undefined>(undefined);

export const GlobalStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(() => {
    const userPreference = localStorage.getItem('darkMode');
    return userPreference ? JSON.parse(userPreference) : window.matchMedia('(prefers-color-scheme: dark)').matches;
  });
  const [isUserToolsMenuVisible, setIsUserToolsMenuVisible] = useState(() => {
    const storedVisibility = sessionStorage.getItem('isUserToolsMenuVisible');
    return storedVisibility !== null ? JSON.parse(storedVisibility) : false;
  });

  // DarkMode
  useEffect(() => {
    document.body.className = darkMode ? 'dark' : '';
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (event: MediaQueryListEvent) => setDarkMode(event.matches);

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return (
    <GlobalStateContext.Provider value={{
      isUserToolsMenuVisible, setIsUserToolsMenuVisible,
      darkMode, toggleDarkMode
    }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};
