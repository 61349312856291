import React, { createContext, useContext, useState, useEffect } from 'react';
import Snackbar from './Snackbar';

interface SnackbarState {
  message: string;
  type: 'success' | 'error';
  duration?: number;
}

export interface SnackbarContextType {
  showSnackbar: (message: string, type: 'success' | 'error', duration?: number) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [snackbarQueue, setSnackbarQueue] = useState<SnackbarState[]>([]);
  const [currentSnackbar, setCurrentSnackbar] = useState<SnackbarState | null>(null);

  useEffect(() => {
    if (!currentSnackbar && snackbarQueue.length > 0) {
      setCurrentSnackbar(snackbarQueue[0]);
      setSnackbarQueue(prev => prev.slice(1));
    }
  }, [currentSnackbar, snackbarQueue]);

  useEffect(() => {
    if (currentSnackbar) {
      const timer = setTimeout(() => {
        setCurrentSnackbar(null);
      }, currentSnackbar.duration || 2000);

      return () => clearTimeout(timer);
    }
  }, [currentSnackbar]);

  const showSnackbar = (message: string, type: 'success' | 'error', duration = 3000) => {
    setSnackbarQueue(prev => [...prev, { message, type, duration }]);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {currentSnackbar && (
        <Snackbar
          message={currentSnackbar.message}
          isOpen={true}
          type={currentSnackbar.type}
        />
      )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
