import { useNavigate, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

function useQueryParamsManager() {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const updateQueryParams = (paramsToUpdate: { [key: string]: string | undefined }) => {
    const currentSearchParams = new URLSearchParams(location.search);
    Object.entries(paramsToUpdate).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        currentSearchParams.delete(key);
      } else {
        currentSearchParams.set(key, value);
      }
    });
    const basePath = location.pathname.split('/')[1];
    const cleanPath = `/${basePath}`;

    navigate({
      pathname: cleanPath,
      search: `?${currentSearchParams.toString()}`,
    }, { replace: true });
  };

  const getQueryParam = (key: string): string => {
    return searchParams.get(key) || '';
  };

  const modifyListInQueryParams = (key: string, id: string, shouldAdd: boolean): void => {
    const currentValues = searchParams.get(key);
    const numberSet = new Set(currentValues ? currentValues.split(',').map(Number) : []);

    const idNumber = parseInt(id, 10);
    if (isNaN(idNumber)) {
      console.error(`Failed to parse id '${id}' as a number.`);
      return;
    }

    if (shouldAdd) {
      numberSet.add(idNumber);
    } else {
      numberSet.delete(idNumber);
    }

    const updatedValues = Array.from(numberSet).join(',');
    updateQueryParams({ [key]: updatedValues || undefined });
  };

  const getIdsFromQueryParam = (param: string): number[] => {
    const stringIds = getQueryParam(param);
    if (!stringIds) {
      return []
    }
    const ids: number[] = [];
    for (const id of stringIds.split(',')) {
      const parsedNumber = Number(id);
      if (!isNaN(parsedNumber)) {
        ids.push(parsedNumber);
      }
    }

    return ids;
  }

  return {
    getIdsFromQueryParam,
    updateQueryParams,
    getQueryParam,
    modifyListInQueryParams
  };
}

export default useQueryParamsManager;
