import React, { useEffect, useState } from 'react';

interface SnackbarProps {
  message: string;
  isOpen: boolean;
  type: 'success' | 'error';
  duration?: number;
}

const Snackbar: React.FC<SnackbarProps> = ({
                                             message,
                                             isOpen,
                                             type,
                                             duration = 2000
                                           }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [isOpen, duration]);

  if (!visible) return null;

  const icon = type === 'success' ? '✅' : '❌';
  const fullMessage = `<span>${icon}</span> ${message}`;

  return (
    <div style={{
      position: 'fixed',
      bottom: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#313748',
      color: 'white',
      padding: '16px',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
      zIndex: 1000,
      display: 'block'
    }}
         dangerouslySetInnerHTML={{ __html: fullMessage }}
    />
  );
};

export default Snackbar;
